
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';

import type CompsetsService from '@/modules/compsets/compsets.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import type ProvidersService from '@/modules/providers/providers.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import { COLUMNS_LIST } from '@/modules/rates/pages/rates-report.modal.page.vue';

import ScheduledItemModel from '../models/scheduled-item.model';
import { ICustomColumn } from '../interfaces';

const ANALYSIS_TRANSLATIONS: Record<string, string> = {
    'past period': 'titles.pastPeriod',
    source: 'titles.provider',
    mealType: 'titles.mealType',
    roomType: 'titles.roomType',
    los: 'titles.los',
    pos: 'titles.pos',
    'number of guest': 'titles.numberOfGuests',
    price: 'titles.price',
};

@Component({
    components: { CustomTooltip },
})
export default class ScheduledReportsTooltip extends Vue {
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    @Prop({
        type: Object,
    })
    private report!: ScheduledItemModel | null;

    @Prop({
        type: [HTMLElement],
    })
    public focusElement!: HTMLElement;

    @Prop({
        type: Array,
        default: () => ([]),
    })
    public columns!: ICustomColumn[];

    get isActive() {
        return !!this.report && !!this.focusElement && this.fields.length > 0;
    }

    private translateAnalysis(key: string, value: any, sourceObject: Record<string, any>) {
        if (key === 'key') {
            return this.$tc(ANALYSIS_TRANSLATIONS[value as unknown as string]);
        }

        switch (sourceObject.key) {
            case 'source':
                return value.map((v: any) => this.providersService.allProviders[v]?.label || v).join(', ');

            case 'mealType':
                return value.map((v: any) => this.$tc(this.mealTypesService.getMealType(v)?.displayName || '')).join(', ');

            case 'roomType':
                return value.map((v: any) => this.$tc(this.roomTypesService.getRoomType(v)?.displayName || '')).join(', ');

            default: break;
        }

        return value;
    }

    get fields() {
        const { report } = this;

        const COLUMNS_TRANSLATIONS: Record<string, string> = {
            ...COLUMNS_LIST,
            change: 'titles.change',
            score: 'titles.score',
        };

        if (!report) {
            return [];
        }

        const tooltip = [];
        const compset = this.compsetsService.getCompset(report.compsetId);

        if (compset) {
            tooltip.push([this.$tc('titles.compset'), compset.name]);
        }

        if (!report.filters) {
            return tooltip;
        }

        Object.entries(report.filters).forEach(([key, value]) => {
            const title = this.$tc(`titles.${_.camelCase(key)}`);
            let fieldValue = '';

            if (!value) {
                return;
            }

            fieldValue = typeof value === 'object'
                ? Object
                    .entries(value)
                    .map(([subkey, item]) => {
                        switch (key) {
                            case 'meal_type': return this.$tc(this.mealTypesService.getMealType(item)?.displayName || String(item));
                            case 'room_type': return this.$tc(String(item));
                            case 'comparison_to': return this.translateAnalysis(subkey, item, value);
                            case 'extra_columns': return item
                                ? this.$tc(COLUMNS_TRANSLATIONS[subkey])
                                : '';
                            case 'columnsOptions': return item
                                ? this.$tc(COLUMNS_TRANSLATIONS[subkey])
                                : '';
                            case 'providers': return this.providersService.allProviders[String(item)]?.label || String(item);
                            default: return String(item);
                        }
                    })
                    .filter(Boolean)
                    .join(', ')
                : {
                    price_type: this.$tc(`filters.price.${value}`),
                    price: this.$tc(`price.${value}`),
                    meal_type: this.$tc(this.mealTypesService.getMealType(value)?.displayName || String(value)),
                    room_type: this.$tc(String(value)),
                    providers: this.providersService.allProviders[String(value)]?.label || String(value),
                }[key] || String(value);

            if (!fieldValue) {
                return;
            }

            tooltip.push([title, fieldValue]);
        });

        return tooltip;
    }
}
