
import type UserService from '@/modules/user/user.service';
import { Prop, Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import IRecipient from '../../../interfaces/recipient.interface';
import Tag from './tag.vue';

interface ITag extends IRecipient {
    id: number;
}

const MAX_TAGS = 15;

@Component({
    components: { Tag },
})
export default class TagsWrapper extends Vue {
    @inject(KEY.UserService) private userService!: UserService;

    @Prop({
        type: Array,
    })
    defaultRecipients!: IRecipient[];

    private tags: IRecipient[] = [];

    mounted() {
        const { user } = this.userService;
        if (!user) {
            return;
        }

        if (this.defaultRecipients.length === 0) {
            // Set dafault user email in case of create New report
            this.tags.push({ value: user.email, isValid: true });
            this.$emit('change', { propName: 0, value: { value: user.email, isValid: true } });
        } else {
            // Set recipients that was get from report in case of Edit existed report
            this.tags = this.defaultRecipients;
        }
    }

    handleCreate() {
        if (this.tags.length >= MAX_TAGS) {
            return;
        }

        this.tags.push({ value: '', isValid: true });
    }

    handleRemove(e: ITag) {
        this.tags = this.tags.filter((_, id) => id !== e.id);
        this.$emit('change', { propName: e.id, value: null });
    }

    handleUpdate(e: ITag) {
        this.tags[e.id].value = e.value;
        this.tags[e.id].isValid = e.isValid;
        this.tags = [...this.tags];

        const { value, isValid } = e;
        this.$emit('change', { propName: e.id, value: { value, isValid } });
    }

    getTagValue(id: number) {
        return this.tags[id].value;
    }
}
